import { RegisterOnsite } from 'utility/types';
import axios, { publicInstance } from './axios';

export interface ClinicInfoInterface {
  branch: {
    address: string;
    amountExaminationRoom: number;
    amphurID: number;
    businessLicense: string;
    businessLicenseID: string | null;
    description: string;
    districtID: number;
    doctorOperatorName: string;
    lineId: string;
    map: { lat: string | number; lng: string | number; long: string | number };
    name: string;
    openTime: string;
    operatorName: string;
    phoneNumber: string;
    provinceID: number;
  };
  clinic: {
    clinicTypeID: string;
    logoID: string | null;
    name: string;
    packageID: string;
  };
}

interface ClinicIdInterface {
  clinicId: string;
  branchId?: string;
}

export interface TreatmentsInterface {
  id: string;
  logo: {
    fileName: string;
    folder: string;
    id: string;
    originalName: string;
    presignUrl: string;
    url: string;
  };
  name: string;
}

export const userApi = {
  updateClinicInfo: (args: ClinicInfoInterface) => axios.put('/clinic', args),
  getClinicTreatments: (
    clinicInfo: ClinicIdInterface,
    signal?: AbortSignal | undefined
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/treatments`,
      {
        signal,
      }
    ),
  getUserJourneys: () => publicInstance.get('customers/journeys'),
  checkPatientPhoneNumber: (phoneNumber: string) =>
    publicInstance.post('patient/check-phonenumber', {
      phoneNumber,
    }),
  registerOnsite: (data?: RegisterOnsite) =>
    publicInstance.post('users/register-onsite', data),
};
