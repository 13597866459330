// ** Store Imports
import { useDispatch, useSelector } from 'react-redux';
import { handleOpen, handleClose } from '@store/modal';

export const useModal = () => {
  // ** Hooks
  const dispatch = useDispatch();
  const store = useSelector((state) => state.modal);

  const onOpen = (value) => {
    dispatch(handleOpen(value));
  };

  const onClose = () => {
    dispatch(handleClose());
  };
  return {
    modal: store,
    onOpen,
    onClose,
  };
};
