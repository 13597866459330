import { PermissionForm, Role } from 'utility/types/role';
import instance from './axios';

export interface ChangeRolePayload {
  fromRoleID: Role['id'];
  toRoleID: Role['id'];
}

export const roleServices = {
  getRoles: (clinicId: string) =>
    instance.get(`clinic/${clinicId}/customer-supports/roles`),
  getPermissions: (clinicId: string) =>
    instance.get(`clinic/${clinicId}/customer-supports/permissions`),
  getRole: (clinicId: string, roleId: string) =>
    instance.get(`clinic/${clinicId}/customer-supports/roles/${roleId}`),
  createRole: (clinicId: string, data: PermissionForm) =>
    instance.post(`clinic/${clinicId}/customer-supports/roles`, data),
  updateRole: (clinicId: string, roleId: string, data: PermissionForm) =>
    instance.patch(
      `clinic/${clinicId}/customer-supports/roles/${roleId}`,
      data
    ),
  changeRole: (clinicId: string, data: ChangeRolePayload) =>
    instance.patch(`clinic/${clinicId}/customer-supports/change-role`, data),
  deleteRole: (clinicId: string, roleId: string) =>
    instance.delete(`clinic/${clinicId}/customer-supports/roles/${roleId}`),
};
