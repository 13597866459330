import styled from 'styled-components';

export const MatchedDetailWarp = styled.div`
  h4 {
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 19px !important;
    color: var(--bs-body-color) !important;
  }
  p {
    font-weight: 600;
    line-height: 25px;
  }
  .headline-text {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 25px !important;
  }
  .header-section {
    h1 {
      font-weight: 700;
      font-size: 26px;
      line-height: 41px;
      color: #191a1c;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    margin-bottom: 1.5em !important;
  }
`;
