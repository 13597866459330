import { FC, lazy, useMemo, useState } from 'react';
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  Row,
} from 'reactstrap';
import useDisclosure from '../../../utility/hooks/useDisclosure';
import { PackageInterface } from '../../../utility/types/package';
import { MatchedDetailWarp } from '../../../components/common/MatchedDetailWarp';
import { IS_STANDARD } from '../../../utility/constants';
import useAlert from '../../../utility/hooks/useAlert';
import { packageServices } from '../../../services';
import ModalTitle from '../../../components/common/ModalTitle/Index';
import { useAuth } from '../../../utility/context/AuthProvider';

const BuyPackage = lazy(() => import('../BuyPackage'));
const PackageCard = lazy(() => import('./PackageCard'));

interface PackageModalProps extends Pick<ModalProps, 'isOpen' | 'onClosed'> {
  packages?: PackageInterface[];
  currentPackage?: string;
  currentPackageName?: string;
}

const PackageModal: FC<PackageModalProps> = ({
  packages,
  isOpen,
  onClosed,
  currentPackage,
}) => {
  const buyPackageDisclosure = useDisclosure();
  const { clinicData } = useAuth();
  const [clinic] = clinicData;
  const { confirmCancel, showErrorMessage, showSuccessMessage } = useAlert();
  const [selectedPackage, setSelectedPackage] = useState<PackageInterface>();

  const onRegister = async (id: string) => {
    const findPackage = packages?.find((val) => val.id === id);
    setSelectedPackage(findPackage);

    if (currentPackage !== IS_STANDARD && findPackage?.name === IS_STANDARD) {
      const isConfirm = await confirmCancel(
        'เปลี่ยนแพ็กเกจเป็น Standard',
        'ใช่, เปลี่ยนแพ็กเกจเป็น Standard'
      );
      if (isConfirm) {
        await downGradePackage();
      }
    } else {
      buyPackageDisclosure.onOpen();
      onClosed?.();
    }
  };

  const downGradePackage = async () => {
    try {
      await packageServices.downGradePackage(clinic.id);
      showSuccessMessage('เปลี่ยนแพ็กเกจสำเร็จ');
    } catch (error: any) {
      showErrorMessage(error);
      switch (error.response.data.error.code) {
        case 409:
          showErrorMessage(error.response.data.error.message);
          break;
        default:
          showErrorMessage(error);
          break;
      }
    } finally {
      onClosed?.();
    }
  };
  const Packages = useMemo(
    () =>
      packages?.map((data, key) => {
        return (
          <Col key={key}>
            <PackageCard
              {...data}
              currentPackage={currentPackage}
              onRegister={onRegister}
            />
          </Col>
        );
      }),
    [packages]
  );

  const onBuyPackageClose = () => {
    setSelectedPackage(undefined);
    buyPackageDisclosure.onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} className='modal-dialog-centered modal-xl'>
        <ModalHeader className='bg-transparent' toggle={onClosed} />
        <ModalBody>
          <MatchedDetailWarp className='container-md'>
            <div className='text-center header-section'>
              <ModalTitle
                title='เลือกแพ็กเกจ'
                subtitle='เลือกซื้อแพ็กเก็จของเราเพื่อเปิดใช้งานฟังชั่นต่าง ๆ '
              />
            </div>
            <Row className='match-height mt-1' sm='3'>
              {Packages}
            </Row>
          </MatchedDetailWarp>
        </ModalBody>
      </Modal>
      <BuyPackage
        packageDetail={selectedPackage}
        isOpen={buyPackageDisclosure.isOpen}
        onClosed={onBuyPackageClose}
      />
    </>
  );
};

export default PackageModal;
