import { RevenueShareInterface } from 'utility/types';
import { BranchInfoInterface } from 'utility/types/branch';
import instance from './axios';

export const branchServices = {
  getBranches: (clincId: string) => instance.get(`/clinic/${clincId}/branches`),
  getBranch: (clincId?: string, branchId?: string) =>
    instance.get(`/clinic/${clincId}/branches/${branchId}`),
  updateBranch: (
    clincId?: string,
    branchId?: string,
    data?: BranchInfoInterface
  ) => instance.put(`/clinic/${clincId}/branches/${branchId}`, data),
  getAllotments: (clincId?: string, branchId?: string) =>
    instance.get(`clinic/${clincId}/branches/${branchId}/allotments`),
  updateAllotments: (
    clincId?: string,
    branchId?: string,
    data?: RevenueShareInterface[]
  ) => instance.put(`clinic/${clincId}/branches/${branchId}/allotments`, data),
};
