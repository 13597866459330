import { useState } from 'react';
import styled from 'styled-components';

import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

// ** Common Component
import ModalTitle from '../../common/ModalTitle/Index';
import PackageModal from '../../../components/management/PackageModal';

// ** Hooks
import { getCookie } from '../../../utility/Utils';
import { useModal } from '../../../utility/hooks/useModal';
import useAlert from '../../../utility/hooks/useAlert';

// ** Services
import { packageServices } from '../../../services';
import usePackage from '../../../utility/hooks/usePackage';

const Warning = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`;

export const ConfirmModal = () => {
  // ** Hooks
  const { modal, onClose } = useModal();
  const { showErrorMessage } = useAlert();
  const { getPackage } = usePackage();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [packages, setPackages] = useState([]);

  const isOpenModal = modal.isOpen && modal.type === 'confirmModal';

  const packageData = getPackage();

  const fetch = async () => {
    try {
      const res = await packageServices.getPackages();
      setPackages(res.data.data.items);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const onOpenHandle = () => {
    fetch();
    setIsOpen(true);
  };

  const onCloseHandle = () => {
    setPackages([]);
    setIsOpen(false);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={onClose}
        centered={true}
        fullscreen={false}
        backdrop={true}
      >
        <ModalHeader className='bg-white' />
        <ModalBody>
          <Warning>
            <img
              className={modal.config?.image?.className}
              src={modal.config?.image?.src}
              alt={modal.config?.image?.alt}
            />
          </Warning>
          <ModalTitle
            title={modal.config?.title}
            subtitle={modal.config?.subtitle}
          />
          <div className='p-2 d-flex justify-content-center'>
            <Button
              color={modal.config?.button?.color}
              type={modal.config?.button?.type}
              disabled={modal.config?.button?.isDisabled}
              onClick={onOpenHandle}
            >
              {modal.config?.button?.text}
            </Button>
            <Button
              outline
              color='dark'
              type='button'
              className='ms-2'
              onClick={onClose}
            >
              ปิด
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <PackageModal
        currentPackage={packageData?.id}
        currentPackageName={packageData?.name}
        packages={packages}
        isOpen={isOpen}
        onClosed={() => onCloseHandle()}
      />
    </>
  );
};
