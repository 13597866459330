import { FC } from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
`;

const SubTitle = styled.p`
  font-size: 14px;
  text-align: center;
  color: #6e6b7b;
  font-weight: 400 !important;
`;

interface ModalTitleProps {
  title: string;
  subtitle: string;
}

const ModalTitle: FC<ModalTitleProps> = ({ title, subtitle }) => {
  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </>
  );
};

export default ModalTitle;
