import { useEffect, createContext, useState, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ClinicInterface,
  NotificationInterface,
  NotificationType,
} from '../../utility/types';

import authServices from '../../services/auth';
import {
  getCookie,
  isUserLoggedIn,
  removeCookie,
  setCookie,
} from '../../utility/Utils';
import useAlert from '../../utility/hooks/useAlert';
import { UserDataPropsInterface } from 'utility/types/auth';
import { IS_OWNER } from '../../utility/constants';
import usePackage from '../../utility/hooks/usePackage';

interface AuthenContextPropType {
  children: React.ReactNode;
}

interface ClinicBranchInterface {
  clinicBranch: ClinicInterface;
}

interface AuthContextInterface {
  isAuthenticated: boolean;
  userData?: UserDataPropsInterface;
  isOwner: boolean;
  clinicData: Array<ClinicInterface>;
  branchesData: Array<ClinicInterface>;
  notification: NotificationInterface | undefined;
  currentBranch: ClinicInterface | null;
  changeClinicBranch: (selected: string) => void;
  logout: () => void;
  getStatus: () => void;
  incNotiCount: (item: NotificationType) => void;
  decNotiCount: (item: NotificationType) => void;
  fetch: () => void;
}

const intitialAuthSate: AuthContextInterface = {
  isAuthenticated: false,
  isOwner: false,
  userData: undefined,
  clinicData: [],
  branchesData: [],
  notification: undefined,
  currentBranch: null,
  changeClinicBranch: () => {
    return;
  },
  logout: () => {
    return;
  },
  getStatus: () => {
    return;
  },
  incNotiCount: () => {
    return;
  },
  decNotiCount: () => {
    return;
  },
  fetch: () => ({}),
};

const AuthCtx = createContext<AuthContextInterface>(intitialAuthSate);

export const useAuth = () => {
  return useContext(AuthCtx);
};

const AuthContext = ({ children }: AuthenContextPropType) => {
  const [userData, setUserData] = useState<UserDataPropsInterface>();
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [mounted, setmounted] = useState<boolean>(false);
  const [clinicData, setClinicData] = useState<Array<ClinicInterface>>([]);
  const [branchesData, setBranchesData] = useState<Array<ClinicInterface>>([]);
  const [notification, setNotification] = useState<NotificationInterface>({
    appointmentTotal: 0,
    customerSupportJobTotal: 0,
    doctorWaitRequestTotal: 0,
  });
  const [selectedBranch, setSelectedBranch] = useState<ClinicInterface | null>(
    null
  );
  const currentBranchRef = useRef(
    getCookie('currentBranch') ? JSON.parse(getCookie('currentBranch')) : null
  );
  let currentBranch = currentBranchRef.current;
  const loginState = isUserLoggedIn();
  const location = useLocation();
  const { setPackage } = usePackage();

  useEffect(() => {
    if (loginState) {
      getUserProfile();
    }
    setmounted(true);
  }, [location, loginState]);

  useEffect(() => {
    const storedUser = getCookie('clinicData');
    if (storedUser) {
      setClinicData(JSON.parse(storedUser));
    }
  }, [location, loginState]);

  const logout = () => {
    removeCookie('accessToken');
    removeCookie('refreshToken');
    removeCookie('currentBranch');
    removeCookie('clinicData');
    setClinicData([]);
    setSelectedBranch(null);
    setIsOwner(false);
    setBranchesData([]);
    setUserData(undefined);
    currentBranch = null;
    currentBranchRef.current = null;
  };

  const changeClinicBranch = (branchId: string) => {
    if (branchId === currentBranch.id) return;

    const data = branchesData.find((item) => item.id === branchId);
    const branch = data ?? branchesData[0];
    setSelectedBranch(branch);
    setCookie('currentBranch', JSON.stringify(branch));
    getPackage(branchId);
    currentBranchRef.current = branch;
  };

  const getPackage = async (branchId: string) => {
    const clinicData = JSON.parse(getCookie('clinicData'));
    const { data } = await authServices.getClinic(clinicData?.id, branchId);
    setPackage(data.data.item.package);
  };

  const getUserProfile = async () => {
    try {
      const res = await authServices.getProfile();
      getStatus();
      if (res.status === 200) {
        const branchs =
          res.data.data.item.clinicCustomerSupport.clinicCustomerSupportBranches.map(
            (item: ClinicBranchInterface) => {
              return item.clinicBranch;
            }
          );
        const clinicDataBranchData = [
          res.data.data.item.clinicCustomerSupport.clinic,
        ];
        setUserData(res.data.data.item);
        setIsOwner(
          res.data.data.item.clinicCustomerSupport.clinicCustomerSupportRole
            .name === IS_OWNER
        );
        setBranchesData(branchs);
        setCookie('clinicData', JSON.stringify(clinicDataBranchData));
        //  set default main branch
        if (!selectedBranch && !currentBranch) {
          setSelectedBranch(branchs[0] ?? null);
          setCookie('currentBranch', JSON.stringify(branchs[0] ?? null));
          currentBranchRef.current = branchs[0] ?? null;
        }
        setmounted(true);
      }
    } catch (error) {
      logout();
      useAlert().showErrorMessage(error as Error);
    }
  };

  const getStatus = async () => {
    if (!currentBranch?.id || !clinicData[0]?.id) return;
    try {
      const { data } = await authServices.getNotification(
        clinicData[0]?.id,
        currentBranch?.id
      );
      setNotification(data.data.item);
    } catch (error) {
      useAlert().showErrorMessage(error as Error);
    }
  };

  const incNoti = (item: NotificationType) => {
    setNotification((prev) => ({
      ...prev,
      [item]: notification[item] + 1,
    }));
  };

  const decNoti = (item: NotificationType) => {
    setNotification((prev) => ({
      ...prev,
      [item]: notification[item] - 1,
    }));
  };

  return (
    <AuthCtx.Provider
      value={{
        isAuthenticated: Boolean(loginState),
        isOwner,
        notification,
        userData,
        clinicData,
        branchesData,
        currentBranch,

        fetch: getUserProfile,
        changeClinicBranch,
        logout,
        getStatus,
        incNotiCount: incNoti,
        decNotiCount: decNoti,
      }}
    >
      {mounted && children}
    </AuthCtx.Provider>
  );
};

export { AuthCtx, AuthContext };
