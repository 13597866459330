// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    type: null,
    config: {},
    isOpen: false,
  },
  reducers: {
    handleOpen: (state, action) => {
      state.isOpen = true;
      state.type = action.payload.type;
      state.config = action.payload.config;
    },
    handleClose: (state) => {
      state.type = null;
      state.config = {};
      state.isOpen = false;
    },
  },
});

export const { handleOpen, handleClose } = modalSlice.actions;

export default modalSlice.reducer;
