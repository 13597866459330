import { ClinicInfoInterface } from 'utility/types';
import axios from './axios';
import { FriendStatusEnum } from 'utility/enums/friend.enum';

export interface DoctorFriendQuery {
  search?: string;
  isFavorite?: boolean;
  take?: FriendStatusEnum;
}

export interface RequestFriend {
  clinicBranchID: string;
  doctorID: string;
}

const friendServices = {
  getCountDoctorFriends: (clincInfo: ClinicInfoInterface) =>
    axios.get(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends/count`
    ),
  getDoctorFriends: (
    clincInfo: ClinicInfoInterface,
    params?: DoctorFriendQuery
  ) =>
    axios.get(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends`,
      { params }
    ),
  requestFriend: (clincInfo: ClinicInfoInterface, data: RequestFriend) =>
    axios.post(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends`,
      data
    ),
  removeFriend: (clincInfo: ClinicInfoInterface, friendId: string) =>
    axios.delete(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends/${friendId}`
    ),
  approveFriend: (clincInfo: ClinicInfoInterface, friendId: string) =>
    axios.patch(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends/${friendId}/approve`
    ),
  cancelFriend: (clincInfo: ClinicInfoInterface, friendId: string) =>
    axios.delete(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends/${friendId}/cancel`
    ),
  favoriteDoctor: (clincInfo: ClinicInfoInterface, friendId: string) =>
    axios.patch(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends/${friendId}/favorite`
    ),
  rejectFriend: (clincInfo: ClinicInfoInterface, friendId: string) =>
    axios.patch(
      `clinic/${clincInfo.clincId}/branches/${clincInfo.branchId}/friends/${friendId}/reject`
    ),
};

export default friendServices;
