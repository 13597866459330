import axios from './axios';
import { OptionStatusEnum } from '../components/appointment/UpdateAppointmentModal/Index';

interface ClinicInfoInterface {
  clinicId: string;
  branchId?: string;
}
export interface AppointmentQuery {
  page?: number;
  perPage?: number;
  search?: string;
  status?: number;
  package?: string;
}

export interface AppointmentQueryInterface {
  page: number;
  perPage: number;
  status?: Array<number>;
  search: string;
  treatments: Array<string>;
  doctor: Array<string>;
  doctorID?: Array<string>;
  statusID?: Array<number>;
  treatmentID?: Array<string>;
  noDoctor?: string;
  fromDate: string;
  toDate: string;
  sort?: string;
  sortColumn?: string;
}

export interface AppointmentFindInterface {
  date: string;
  time: string;
  doctorID: string;
  subID: string;
  currentDate?: string;
  exceptAppointmentID?: string;
}
export interface CreateAppointmentI {
  appointmentedAt: string;
  period: string;
  clinicBranchSubTreatmentID: string;
  treatmentId: string;
  patientPhoneNumber: string;
  confirmedAt: string;
  doctorID: string;
  patientID: string | null;
  patientName: string;
  specialNote: string;
}
export interface CreateAppointmentPayloadI {
  appointmentedAt: string;
  period: string;
  clinicBranchSubTreatmentID: string;
  treatmentId: string;
  patientPhoneNumber: string;
  confirmedAt: string | null;
  doctorID: string;
  patientID: string | null;
  patientName: string;
  specialNote: string;
}

export interface UpdateAppointmentI {
  Note: string;
  appointmentedAt: string;
  clinicBranchSubTreatmentID: string;
  treatmentId: string;
  confirmedAt: string;
  doctorID: string;
  specialNote: string;
  statusID: number;
  period: string;
  changeStatus: OptionStatusEnum;
}
export interface UpdateAppointmentPayloadI {
  Note: string;
  appointmentedAt: string;
  clinicBranchSubTreatmentID: string;
  treatmentId: string;
  confirmedAt: string | null;
  doctorID: string;
  specialNote: string;
  statusID: number;
  period: string;
}
export const AppointmentServices = {
  getAppointment: (
    clinicInfo: ClinicInfoInterface,
    params: Partial<AppointmentQueryInterface>
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments`,
      { params }
    ),
  getAppointmentById: (
    clinicInfo: ClinicInfoInterface,
    appointmentId: string
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentId}`
    ),
  getPatientByUserId: (clinicInfo: ClinicInfoInterface, userID: string) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/users/${userID}`
    ),
  createAppointment: (
    clinicInfo: ClinicInfoInterface,
    payload: CreateAppointmentPayloadI
  ) =>
    axios.post(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments`,
      payload
    ),
  updateAppointment: (
    clinicInfo: ClinicInfoInterface,
    appointmentID: string,
    payload: UpdateAppointmentPayloadI
  ) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentID}`,
      payload
    ),
  updateRequestAppointment: (
    clinicInfo: ClinicInfoInterface,
    appointmentID: string,
    payload: UpdateAppointmentPayloadI
  ) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentID}/pending-confirm`,
      payload
    ),
  getLatestPatient: (clinicInfo: ClinicInfoInterface) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/patients/last-registerd`
    ),
  blindPatientByUserId: (
    clinicInfo: ClinicInfoInterface,
    userID?: string,
    appointmentID?: string
  ) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentID}/bind`,
      { userID: userID }
    ),
  getBranchSubTreatment: (
    clinicInfo: ClinicInfoInterface,
    params?: { isActive: boolean }
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/sub-treatments`,
      { params }
    ),
  getPatientBySearch: (
    clinicInfo: ClinicInfoInterface,
    params: Partial<AppointmentQueryInterface>
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/patients/search`,
      { params }
    ),
  getAppointmentDoctors: (
    clinicInfo: ClinicInfoInterface,
    treatmentId: string
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/treatments/${treatmentId}/doctors`
    ),
  getDoctors: (
    clinicInfo: ClinicInfoInterface,
    treatmentId: string,
    subTreatmentID: string,
    params: Partial<AppointmentFindInterface>
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/treatments/${treatmentId}/sub-treatments/${subTreatmentID}/doctors`,
      { params }
    ),
  getDoctorWorkingDays: (
    clinicInfo: ClinicInfoInterface,
    treatmentId: string,
    subTreatmentID: string,
    params: Partial<AppointmentFindInterface>
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/treatments/${treatmentId}/sub-treatments/${subTreatmentID}/doctors/working-days`,
      { params }
    ),
  getDoctorPeriods: (
    clinicInfo: ClinicInfoInterface,
    treatmentId: string,
    subTreatmentID: string,
    params: Partial<AppointmentFindInterface>
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/treatments/${treatmentId}/sub-treatments/${subTreatmentID}/doctors/periods`,
      { params }
    ),
  preCheckAppointmentConfirm: (
    clinicInfo: ClinicInfoInterface,
    appointmentId: string
  ) =>
    axios.post(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/check/${appointmentId}`
    ),
  preCheckAppointment: (
    clinicInfo: ClinicInfoInterface,
    payload: CreateAppointmentPayloadI
  ) =>
    axios.post(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/check`,
      payload
    ),
  appointmentConfirm: (
    clinicInfo: ClinicInfoInterface,
    appointmentID: string
  ) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentID}/confirm`
    ),
  appointmentCancel: (clinicInfo: ClinicInfoInterface, appointmentID: string) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentID}/reject`
    ),
  getAppointmentStatuses: (clinicInfo: ClinicInfoInterface) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/statuses`
    ),
  getPatientByPhoneNumber: (
    clinicInfo: ClinicInfoInterface,
    phoneNumber: string
  ) =>
    axios.get(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/patients/phone-numbers/${phoneNumber}`
    ),
  branchConfirmAppointment: (
    clinicInfo: ClinicInfoInterface,
    appointmentId: string
  ) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentId}/confirm`
    ),
  branchCancelAppointment: (
    clinicInfo: ClinicInfoInterface,
    appointmentId: string
  ) =>
    axios.patch(
      `/clinic/${clinicInfo.clinicId}/branches/${clinicInfo.branchId}/appointments/${appointmentId}/cancel`
    ),
};
