import { BuyPackageInterface } from 'components/management/BuyPackage';
import instance, { publicInstance } from './axios';

export const packageServices = {
  getPackages: () => publicInstance.get('/package'),
  buyPackage: (
    clinicId?: string,
    packageId?: string,
    data?: BuyPackageInterface
  ) => instance.post(`clinic/${clinicId}/packages/${packageId}`, data),
  downGradePackage: (clinicId?: string) =>
    instance.post(`/clinic/${clinicId}/packages/standard`),
};
