import { ClinicInfoForm } from 'utility/types/clinic';
import instance from './axios';

export interface ClinicQuery {
  page?: number;
  perPage?: number;
  search?: string;
  status?: string;
  package?: string;
  branchID?: string;
}

export const clinicServices = {
  getClinic: (clincId: string, params?: ClinicQuery) =>
    instance.get(`/clinic/${clincId}`, { params }),
  updateClinicInfo: (clincId: string, data: ClinicInfoForm) =>
    instance.patch(`/clinic/${clincId}/info`, data),
  getPackages: (clincId: string, params?: ClinicQuery) =>
    instance.get(`/clinic/${clincId}/packages`, { params }),
};
