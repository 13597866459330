import { ConfirmModal } from '../../modals/ConfirmModal';
import useIsMounted from '../../../utility/hooks/useIsMounted';

export const BaseModal = () => {
  const isMounted = useIsMounted();

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <ConfirmModal />
    </>
  );
};
